import React from 'react';
import Aux from '../../hoc/Aux/Aux';
import CustomerLogo from '../CampaignLogo/CampaignLogo';
import CustomerLogoImage from '../../assets/images/logo-brand-customer.svg';
import FooterMenu from '../Navigation/FooterMenu/FooterMenu';
import classes from './Footer.module.scss';

import { Row, Col } from 'reactstrap';
import DynamicToast from "../UI/Toast/Toast";

const footer = (props) => (
    <>
        <Row className={classes.Footer}>
            <Col lg={3} className="d-flex align-items-center order-last order-lg-first py-3">
                <CustomerLogo
                    linkUrl={process.env.REACT_APP_CUSTOMER_URL}
                    src={CustomerLogoImage}/>
            </Col>

            <Col lg={9} className="d-flex align-items-center justify-content-lg-end justify-content-start order-first order-lg-last">
                <FooterMenu secondaryNavigation={props.secondaryNavigation} />
            </Col>
        </Row>
        <DynamicToast/>
    </>
);

export default footer;
