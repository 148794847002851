import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCheckCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from 'reactstrap';
import moment from 'moment';
import Startgrafik from '../../../assets/images/ssw2025-icons-startgrafik-rgb.svg'

const campaignFlow = (props) => {
    return (
        <img src={Startgrafik}
             style={{ width: '100%' }}
             alt="Startgrafik - 400.000 Euro Fördersumme für Schulprojekte."/>
    )
};

export default campaignFlow;

